<template>
    <div class="order-detail__body">
        <header class="order-detail__body_row">
            <p class="orders-details-tabs__tab-description">
                <InformationOutline />
                Maximum allowed upload filesize is {{ maxFileSize | file_size }}.
            </p>
        </header>
        <div class="order-detail__body_row">
            <FormFilesUploader
                :is-uploading-in-progress="isUploadingInProgress"
                :upload-progress="uploadProgressValue"
                @uploadFiles="uploadFiles"
            />
        </div>
        <div class="order-detail__body_row">
            <FilesTable
                v-if="uploadedFiles.length > 0"
                :files="uploadedFiles"
                @deleteFile="deleteFile"
            />
        </div>
    </div>
</template>

<script>
import { uploadFiles, deleteFile, additionalMarkAsRead } from '@/services/files'
import InformationOutline from 'mdi-vue/InformationOutline';
import filtersMixin from '@/mixins/filters-mixin'
import { eventBus } from '@/helpers/event-bus'

// Components
import FilesTable from '@/components/common/files/FilesTable.vue';

export default {
    components: {
        InformationOutline,
        FilesTable
    },
    mixins: [
        filtersMixin
    ],
    props: {
        order: {
            type: Object,
            required: true
        }
    },
    data: () => ({
        uploadedFiles: [],
        isUploadingInProgress: false,
        uploadProgressValue: 0
    }),
    computed: {
        maxFileSize() {
            return Number(process.env.VUE_APP_MAX_BODY_SIZE) || 104857600
        },
        unreadFilesIds() {
            const unreadFiles = this.uploadedFiles.filter((file) => file.is_unread)
            return unreadFiles.map((file) => file.id)
        }
    },
    watch: {
        'unreadFilesIds.length': async function (val) {
            if (val) {
                await this.filesMarkRead()
            }
        }
    },
    async created() {
        this.uploadedFiles = [...this.order.files_add].reverse()
    },
    methods: {
        checkFileSize(filesArr) {
            const calculateFileSize = filesArr.reduce((acc, curr) => {
                acc += curr.size
                return acc
            }, 0)

            if (calculateFileSize > this.maxFileSize) {
                eventBus.$emit('showSnackBar', 'The file cannot be larger than 100MB', 'error');
                return false
            }
            return true
        },
        async uploadFiles(filesArr) {
            if (!this.checkFileSize(filesArr)) return
            try {
                this.isUploadingInProgress = true
                const data = await uploadFiles(filesArr, this.$route.params.id, (e) => {
                    this.uploadProgressValue = (Math.floor((e.loaded * 100) / e.total) - 1);
                })
                this.uploadedFiles = [...data, ...this.uploadedFiles]
                this.$emit('fileUploaded')
                eventBus.$emit('showSnackBar', 'File was successfully uploaded', 'success');
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error');
            } finally {
                this.isUploadingInProgress = false
            }
        },
        async filesMarkRead() {
            try {
                const requests = []
                this.unreadFilesIds.forEach((id) => {
                    requests.push(additionalMarkAsRead(id))
                })
                await Promise.all(requests)
                setTimeout(() => {
                    [...this.unreadFilesIds].forEach((id) => {
                        const findIndex = this.uploadedFiles.findIndex((item) => item.id === id)
                        this.uploadedFiles.splice(findIndex, 1, {
                            ...this.uploadedFiles[findIndex],
                            is_unread: false
                        })
                    })
                    this.$emit('markAsReadAdditional')
                }, 10000);
            } catch (error) {
                console.error(error);
            }
        },
        async deleteFile(file) {
            const modalTitle = 'Delete file'
            const modalText = `Do you really want to delete the ${file.filename}`
            if (await this.$root.$confirm(modalTitle, modalText, { color: 'red' })) {
                try {
                    await deleteFile(file.file_id)
                    this.uploadedFiles = this.uploadedFiles.filter((item) => item.id !== file.id)
                    eventBus.$emit('showSnackBar', `File "${file.filename}" has been deleted`, 'success');
                    this.$emit('deleteFile')
                } catch (error) {
                    eventBus.$emit('showSnackBar', error.message, 'error');
                }
            }
        }
    }
}
</script>
